.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.linksList {
  display: flex;
  flex-direction: column;
    justify-content: center; /* Centers the elements horizontally */
    align-items: center;    /* Centers the elements vertically */
    gap: 20px;              /* Adds space between the boxes */
    padding: 20px;          /* Adds some padding around the container */
    /*background-color: #f5f5f5;*/ /* Light grey background for the container */
}

.linksList a {
  display: block;            /* Makes the anchor tags block elements */
  padding: 15px 30px;        /* Adds padding inside the boxes */
  text-decoration: none;     /* Removes the underline from the links */
  color: white;              /* Sets the text color to white */
  background-color: #007bff; /* Sets the background color of the boxes to blue */
  border-radius: 5px;        /* Adds slightly rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  transition: background-color 0.3s ease;   /* Smooth transition for background color change */
}

.linksList a:hover {
  background-color: #0056b3; /* Changes background on hover for a darker blue */
}

.imageGrid {
  display: flex;
  flex-wrap: wrap;         /* Allows the elements to wrap to the next line */
  justify-content: center; /* Centers the grid items horizontally */
  gap: 20px;               /* Adds spacing between the grid items */
  padding: 20px;           /* Adds padding around the container */
  /*background-color: #f5f5f5;*/ /* Light grey background for the container */
}

.imageGrid a {
  display: block;          /* Makes the anchor tags block elements */
  max-width: 480px;        /* Sets a maximum width for the images */
  max-height: 432px;       /* Sets a maximum height for the images */
  width: 100%;             /* Ensures the images take up full width of their container */
}

.imageGrid img {
  width: 100%;             /* Makes the images take up full width of their parent anchor tags */
  height: auto;            /* Keeps the aspect ratio of the images */
  border-radius: 10px;     /* Slightly round the corners of the images */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow to the images */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.imageGrid img:hover {
  transform: scale(1.05);  /* Gently scales up the images when hovered */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
